
import { defineComponent } from 'vue'
import iconsMap from '@/assets/iconsMap.json'

export default defineComponent({
  name: 'Icons',
  setup() {
    const icons = iconsMap

    return { icons }
  },
})
