import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6954582a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icons-page" }
const _hoisted_2 = { class: "icons-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_icon, { name: "clear" }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
        return (_openBlock(), _createElementBlock("div", {
          key: icon,
          class: "lh-20 mb-1"
        }, [
          _createVNode(_component_tm_icon, {
            name: icon,
            size: "xLarge"
          }, null, 8, ["name"]),
          _createElementVNode("span", null, " - " + _toDisplayString(icon), 1)
        ]))
      }), 128))
    ])
  ]))
}